import { defaultKeymap, history} from '@codemirror/commands';
import { bracketMatching, defaultHighlightStyle, foldGutter, syntaxHighlighting } from '@codemirror/language';
import { EditorState } from '@codemirror/state';
import { EditorView, drawSelection, dropCursor, highlightSpecialChars, keymap, lineNumbers, placeholder } from '@codemirror/view';
import toggle from '../vendor/tracy/tracy/src/Tracy/assets/toggle.js';
import dumper from '../vendor/tracy/tracy/src/Tracy/Dumper/assets/dumper.js';
import naja from 'naja';
import netteForms from 'nette-forms';

function editorFromTextArea(textareaId, extensions = []) {
    let containerClass = 'cm-container';
    let container = document.createElement('div');
    let textarea = document.getElementById(textareaId);
    var textareaChangeEvent = new Event('change');
    let view = new EditorView({
        doc: textarea.value,
        extensions: [
            EditorState.allowMultipleSelections.of(true),
            EditorState.readOnly.of(textarea.disabled),
            EditorView.theme({
                '&.cm-editor': {
                    height: '100%',
                    '&.cm-focused': {
                        outline: '0px none'
                    }
                },
                '.cm-placeholder': {
                    color: '#767676'
                }
            }),
            EditorView.updateListener.of((view) => {
                if (view.docChanged) {
                    textarea.value = view.state.doc.toString();
                    textarea.dispatchEvent(textareaChangeEvent);
                    textarea.removeAttribute('aria-invalid');
                    container.removeAttribute('data-invalid');
                    view.view.contentDOM.removeAttribute('aria-invalid');
                }
            }),
            bracketMatching(),
            drawSelection(),
            dropCursor(),
            foldGutter(),
            highlightSpecialChars(),
            history(),
            keymap.of([
                ...defaultKeymap
            ]),
            lineNumbers(),
            placeholder(textarea.getAttribute('placeholder')),
            syntaxHighlighting(defaultHighlightStyle, {fallback: true}),
            ...extensions
        ]
    });
    container.classList.add(containerClass);
    container.appendChild(view.dom);
    if(textarea.getAttribute('aria-invalid') === 'true') {
        container.setAttribute('data-invalid', 'true');
        view.contentDOM.setAttribute('aria-invalid', 'true');
    }
    if(textarea.hasAttribute('aria-labelledby') === true) {
        view.contentDOM.setAttribute('aria-labelledby', textarea.getAttribute('aria-labelledby'));
    }

    let previousInstances = textarea.parentNode.getElementsByClassName(containerClass);
    while(previousInstances.length){
      previousInstances[0].parentNode.removeChild(previousInstances[0])
    }

    textarea.parentNode.insertBefore(container, textarea);
    textarea.style.display = 'none';
    textarea.required = false;
    if (textarea.form) {
        textarea.form.addEventListener('submit', () => {
            textarea.value = view.state.doc.toString();
            textarea.dispatchEvent(textareaChangeEvent);
        });
    }
    return view;
}

function flashMessageClosable(selector) {
    let elements = document.querySelectorAll(selector);
    for (let element of elements) {
        let template = document.createElement('div');
        template.innerHTML = '<i class="close-button"><span aria-hidden="true">&times;</span></i>';
        let closeButton = template.firstElementChild;
        closeButton.addEventListener('click', (event) => {
            event.stopPropagation();
            element.style.opacity = '0';
            element.addEventListener('transitionend', () => {
                element.remove();
            });
        });
        element.appendChild(closeButton);
    }
}

function formAutoSubmit(query) {
    let element = document.querySelector(query);
    element.form.querySelector('button').style.display = 'none';
    element.addEventListener('change', () => {
        element.form.submit();
    });
}

function labelSwitch() {
    let elements = document.querySelectorAll('[data-label-check]');
    for (let element of elements) {
        let checkElement = document.querySelector(element.getAttribute('data-label-check'));
        let labelEmpty = element.getAttribute('data-label-empty');
        let labelFilled = element.getAttribute('data-label-filled');

        let processSwitch = () => {
            let empty = (checkElement.value === '');
            if ((empty === true) && (element.textContent !== labelEmpty)) {
                element.textContent = labelEmpty;
            } else if((empty === false) && (element.textContent !== labelFilled)) {
                element.textContent = labelFilled;
            }
        };

        processSwitch();
        checkElement.addEventListener('change', processSwitch);
    }
}

function setup() {
    editorFromTextArea('pacc').focus();
    editorFromTextArea('text');
    flashMessageClosable('.flashes > div');
    formAutoSubmit('header form select');
    labelSwitch();
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelector('html').classList.remove('no-js');

    setup();

    naja.initialize();
    naja.formsHandler.netteForms = netteForms;
    naja.addEventListener('complete', () => {
        setup();
    });
});
